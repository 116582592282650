@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.retro {
  &__wrapper {
    background-color: #f5f5f5;
    padding-bottom: 2.3rem;
    @include breakpoint($md) {
      padding: 2.3rem 0;
      .container-fluid {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
  &__container {
    margin-top: 1rem;
    @include breakpoint($md) {
      margin-top: 0;
    }
    &__retro-catalogue {
      overflow-x: scroll;
      /* width */

      &__cards {
        display: flex;
        width: max-content;
        margin-top: 1rem;
        padding-bottom: 10px;
      }
      &__card {
        background-color: #fff;
        height: 164px;
        width: 164px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 5px;
        text-decoration: none;
        transition: all 0.2s ease;
        &:hover {
          box-shadow: 0 5px 7px 4px rgba(195, 195, 195, 0.32);
          transform: translateY(-10px);
        }
        &:not(:first-child) {
          margin-left: 1rem;
        }
        img {
          max-height: 70%;
          max-width: 95%;
        }
        h6 {
          font-size: 18px;
          color: #242952;
          letter-spacing: 0;
        }
      }
    }
    .product__card {
      position: relative;
      //z-index: 0;
      &:hover {
        transform: unset;
        box-shadow: unset;
      }
      &.withDiscount {
        padding-top: 2rem;
      }
      &__discount {
        background-color: $blue;
        // background-color: #e9392f;
        // border: 2px solid $red;
        border-radius: 5px;
        display: flex;
        left: 1rem;
        padding: 5px 5px;
        position: absolute;
        text-align: center;
        top: 10px;
        z-index: 10;
        @include breakpoint($md) {
          padding: 5px 7px;
        }
        span {
          color: #fff !important;
          // color: #924a01 !important;
          padding: 0;
          font-size: 0.85rem !important;
          font-family: $main-font;
          letter-spacing: 0.5px;
          @include breakpoint($md) {
            font-size: 0.9rem !important;
          }
        }
      }
      img {
        width: 95%;
        height: auto;
        transition: all 0.2s ease;
        position: relative;
        //z-index: 9999999999999999 !important;
        // &:hover {
        //   transform: scale(1.5, 1.5);
        //   // position: absolute;
        //   // top: 0;
        //   // left: 2rem;
        // }
        // // &:not(:hover) {
        // //   z-index: 2;
        // // }

        @include breakpoint($md) {
          max-width: 250px;
        }
      }
      .btn--blue {
        background: $blue;
        border: none;
        font-family: $main-font;
        color: #fff;
        width: 100%;
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px 5px;
        transition: all 0.3s ease-in-out;
        @include breakpoint($md-down) {
          font-size: 1rem;
          height: 45px;
          margin: 1rem auto 0 auto;
          width: 100%;
        }
        &:hover {
          background-color: #c91417;
        }
      }
    }
    &__products {
      @include breakpoint($md-down) {
        overflow-x: hidden;
      }
      .row {
        .col {
          position: relative;
          // z-index: 2;
        }
      }
      &__search-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-bottom: 10px;
        label {
          font-family: $main-font;
        }
        .hide {
          display: none;
        }
        input {
          padding: 10px 10px;
          @include breakpoint($lg) {
            padding: 10px;
            width: 40%;
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $purple;
  &:hover {
    background: darken($purple, 5%);
    cursor: not-allowed !important;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($purple, 5%);
  cursor: not-allowed !important;
}
