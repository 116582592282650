@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.top-nav {
  background-image: linear-gradient(
    90deg,
    #56e39f 8%,
    #3454d1 53%,
    #613f75 96%
  );
  padding: 0.51rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include breakpoint($md) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  a,
  span {
    // display: flex;
    font-family: $main-font;
    color: #fff;
    text-decoration: none;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    img {
      height: 20px;
    }
  }
  &__social {
    a {
      img {
        height: 29px;
        @include breakpoint($md) {
          height: 30px;
        }
      }
    }
  }
  &__promotion {
    display: flex;
    align-items: center;
    h6 {
      color: #fff;
      margin-left: 7px;
      font-size: 0.85rem;
      @include breakpoint($md) {
        font-size: 1.1rem;
      }
    }
    img {
      @include breakpoint($xs) {
        display: none;
      }
      display: block;
    }
  }
  &__branches {
    @include breakpoint($md-down) {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.custom-nav {
  flex-direction: column;
  border-bottom: 2px solid #ebebeb;
  padding-bottom: 15px;
  @include breakpoint($md) {
    padding-top: 0.3rem;
    padding-bottom: 15px;
  }
  & > div {
    &:nth-child(2) {
      @include breakpoint($md) {
        margin-top: -1rem;
      }
    }
  }
  .container {
    @media (min-width: 1024px) and (max-width: 1099px) {
      max-width: 990px;
    }
    @media (min-width: 1100px) and (max-width: 1181px) {
      padding-left: 0;
      padding-right: 0;
      max-width: 1100px;
    }
    @include breakpoint($lg) {
      max-width: 1240px;
    }
    @include breakpoint($lg2) {
      max-width: 1340px;
    }
    // max-width: 1320px;
  }
  .navbar__search {
    position: relative;
    @include breakpoint($sm-only) {
      width: 100%;
    }
    @media (min-width: 1000px) {
      margin-top: -1rem;
      width: 900px;
    }
    width: 550px;
    @media (min-width: 1024px) and (max-height: 769px) {
      margin-top: -1rem;
      width: 800px;
    }
    // @include breakpoint($md) {
    //   width: 600px;
    // }
    @include breakpoint($lg) {
      margin-top: -1rem;
      width: 700px;
    }
    input {
      background: #f6f6f6;
      box-shadow: 0 2px 3px 0 rgba(106, 106, 106, 0.15);
      border: 1px solid #ebebeb;
      border-radius: 1px;
      font-family: $secondary-font;
      height: 45px;
      font-size: 1rem;
      position: relative;
      z-index: 9;
    }
    &__results {
      position: absolute;
      background-color: #fbfbfb;
      // box-shadow: 0 5px 8px 3px rgba(103, 55, 123, 0.18);

      // padding: 1rem;

      width: 100%;
      z-index: 3;
      top: 55px;

      &__container {
        max-height: 360px;
        overflow-y: scroll;
        z-index: 9;
        position: relative;
        @include breakpoint($md) {
          max-height: 300px;
        }
      }
      ::-webkit-scrollbar-thumb {
        background: $blue !important;
      }
      &.show {
        border-radius: 10px;
        border: 2px solid #c9c9c9;
        overflow-y: auto;
        &::before {
          content: "";
          opacity: 0.5;
          position: fixed;
          top: 0;
          left: 0;
          background-color: #fff;
          z-index: 1;
          width: 100%;
          height: calc(110vh * 3.2);
        }
      }
      a {
        font-family: $main-font;
        display: block;
        padding: 15px 10px;
        color: #000;
        text-decoration: none;
        font-size: 1.05rem;
        @include breakpoint($md) {
          font-size: 1.1rem;
          padding: 10px;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #c9c9c9;
        }
        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        &:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &:hover {
          background-color: rgb(243, 243, 243);
        }
        img {
          margin-right: 10px;
          display: none;
          @include breakpoint($md) {
            display: inline-block;
            max-height: 65px;
          }
        }
      }
      h6 {
        padding: 10px;
      }
    }
  }
  .navbar {
    &-actions {
      display: flex;
      @include breakpoint($md-down) {
        display: none;
      }
      &--mobile {
        display: none;
        margin-left: auto;
        // margin-right: 1rem;
        @include breakpoint($md-down) {
          display: flex;
        }
        a {
          &:first-of-type {
            // margin-right: 10px;
            @include breakpoint($md) {
              margin-right: 15px;
            }
          }
        }
        .branches {
          height: 26px;
        }
      }
    }
    &-auth {
      color: rgb(107, 107, 107);
      font-family: $main-font;
      text-decoration: none;
      @include breakpoint($md) {
        margin-right: 1.5rem;
      }
    }
    &-brand {
      img {
        width: 111px;
        @include breakpoint($md) {
          width: 155px;
        }
      }
    }
    &-toggler {
      border: none;

      @media (max-width: 992px) {
        height: 50px;
        display: block;
      }
      &-icon {
        background-image: url("../../assets/img/hamburguer-icon.svg") !important;
      }
    }
    &-collapse {
      font-family: $main-font;
    }
    &-perk {
      margin-top: -1rem;
      display: flex;
      @include breakpoint($md-down) {
        display: none;
      }
      @media (max-width: 1250px) {
        display: none;
      }
      @media (min-width: 1024px) and (max-height: 769px) {
        margin-left: 1rem;
      }
      &:hover {
        cursor: pointer;
        a {
          text-decoration-line: underline;
        }
      }
      a {
        font-size: 1.05rem;
        font-family: $main-font;
        color: #000;
        margin-left: 10px;
        text-decoration: none;
        line-height: 17px;
      }
    }
    &-shopping-bag {
      position: relative;
      margin-right: 5px;
      span {
        align-items: center;
        background-color: $blue;
        border-radius: 50%;
        color: #fff;
        display: flex;
        font-family: $main-font;
        height: 18px;
        justify-content: center;
        position: absolute;
        right: -7px;
        top: -5px;
        width: 18px;
      }
    }
    &-nav {
      p {
        font-size: 1.1rem;
        font-family: $main-font;
        margin-bottom: 0;
        padding: 0.5rem 0;
      }
      .nav-link {
        font-size: 1.1rem;
        color: #000;
        @include breakpoint($md) {
          padding-top: 0.3rem;
          padding-bottom: 0;
          margin-right: 1rem;
        }
      }

      .dropdown-menu {
        padding: 0;
        border: 0;
        @include breakpoint($md-down) {
          width: 100%;
          position: static !important;
          transform: none !important;
        }
      }
      #navbarXboxDropdownContent,
      #navbarPlayStationDropdownContent,
      #navbarNintendoDropdownContent,
      #navbarSegaDropdownContent {
        border-radius: 5px;
        padding: 1rem 0.2rem;
        position: relative;
        z-index: 2;
        @include breakpoint($md) {
          min-height: 230px;
          min-width: 220px;
        }
        &::before {
          content: "";
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          z-index: 1;
        }
        .dropdown-submenu-container {
          .dropdown-menu {
            background: #ffffff;
            box-shadow: 0 6px 10px 6px rgba(24, 38, 86, 0.08);
            border-radius: 5px;
            color: #000;
            padding: 1.2rem;
            @include breakpoint($md-down) {
              padding: 0.7rem;
              color: #fff;
            }
            h6 {
              font-size: 16px;
              color: #999999;
              letter-spacing: 0;
              line-height: 16px;
              font-weight: lighter;
              margin-bottom: 10px;
              display: none;
              @include breakpoint($md) {
                display: block;
              }
            }
            a {
              padding-left: 0;
              display: flex;
              align-items: center;
              font-size: 18px;
              @include breakpoint($md-down) {
                padding-left: 10px;
                color: #fff;
              }
              &:hover,
              &:focus {
                @include breakpoint($md) {
                  background-color: #fbfbfb;
                }
              }
              &:last-child {
                margin-top: 5px;
              }
              img {
                width: 34px;
                margin-right: 10px;
                display: none;
                @include breakpoint($md) {
                  display: block;
                }
              }
            }
          }
        }
        a {
          color: #fff;
          font-size: 18px;
          z-index: 2;
          position: relative;
          &:hover {
            cursor: pointer !important;
          }
        }
        .dropdown {
          &-toggle {
            color: #fff;
            font-size: 18px;
          }
          &-submenu-container {
            z-index: 2;
          }
          &-item {
            &:hover,
            &:focus,
            &:active {
              background-color: #026e02;
            }
          }
          &-menu {
            a {
              color: #999999;
            }
          }
        }
      }
      #navbarXboxDropdownContent {
        // min-width: 413px;
        background-color: #037f04;
        @include breakpoint($md) {
          min-height: unset;
          height: 180px;
        }
        &::before {
          content: "";
          background-image: url("../../assets/img/xbox-logo-opacity.png");
          width: 164px;
          height: 164px;
          left: 0;
        }
        .dropdown-submenu-container {
          .dropdown-menu {
            @include breakpoint($md-down) {
              background-color: #026e0289;
            }
          }
        }
        .dropdown {
          &-item {
            &.active {
              // color: #026e02fe;
              background-color: #fbfbfb !important;
              @include breakpoint($md-down) {
                background-color: #026e02fe !important;
              }
            }
            &:hover,
            &:focus,
            &:active {
              background-color: #026e02fe;
              cursor: pointer !important;
            }
          }
        }
      }

      #navbarPlayStationDropdownContent {
        background-image: linear-gradient(180deg, #003e93 0%, #157dc2 100%);
        &::before {
          content: "";
          background-image: url("../../assets/img/ps-logo-opacity--mobile.png");
          width: 150px;
          height: 150px;
          right: 1.5rem;
          background-size: contain;
          top: 1rem;
          @include breakpoint($md) {
            top: 0;
            right: unset;
            left: 0;
            background-image: url("../../assets/img/ps-logo-opacity.png");
            width: 125px;
            height: 125px;
          }
        }
        .dropdown-submenu-container {
          .dropdown-menu {
            @include breakpoint($md-down) {
              background-color: #014195a5;
            }
          }
          .dropdown-item {
            &.active {
              background-color: #f9f9f9 !important;
              @include breakpoint($md-down) {
                background-color: #014195a5 !important;
              }
              &:hover {
                background-color: #f9f9f9 !important;
              }
            }
          }
        }
        .dropdown {
          &-item {
            &:hover,
            &:focus,
            &:active {
              background-color: #014195a5;
            }
          }
        }
      }
      #navbarNintendoDropdownContent {
        background-image: linear-gradient(-19deg, #e80213 58%, #f23e4a 94%);
        box-shadow: 0 2px 10px 6px rgba(0, 0, 0, 0.04);
        &::before {
          content: "";
          background-image: url("../../assets/img/nintendo-logo--mobile.png");
          width: 220px;
          height: 65px;
          right: 15px;
          top: 1.5rem;
          background-size: contain;
          @include breakpoint($md) {
            top: 1rem;
            left: 1rem;
            right: unset;
            background-image: url("../../assets/img/nintendo-logo-opacity.png");
            height: 125px;
            width: 100%;
          }
        }
        .dropdown-submenu-container {
          .dropdown-menu {
            @include breakpoint($md-down) {
              background-color: #c6000e82;
            }
            .dropdown-item {
              &.active {
                background-color: #f9f9f9 !important;
                @include breakpoint($md-down) {
                  background-color: #c6000e82 !important;
                }
              }
              &:hover {
                background-color: #f9f9f9 !important;
              }
            }
          }
        }
        .dropdown {
          &-item {
            &:hover,
            &:focus,
            &:active {
              // background-color: #fbfbfb !important;
              background-color: #c6000e82 !important;
              @include breakpoint($md-down) {
              }
            }
            &.active {
              background-color: #c6000e82 !important;
            }
          }
        }
      }
      #navbarSegaDropdownContent {
        background-color: #0000ff;
        box-shadow: 0 2px 10px 6px rgba(0, 0, 0, 0.04);
        // min-width: 170px;
        &::before {
          content: "";
          background-image: url("../../assets/img/sega-icon--opacity.png");
          width: 155px;
          height: 50px;
          top: 1.5rem;
          right: 2rem;
          background-size: contain;
          @include breakpoint($md) {
            top: unset;
            bottom: 10px;
            width: 126px;
            height: 39px;
            right: unset;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .dropdown-submenu-container {
          .dropdown-menu {
            @include breakpoint($md-down) {
              background-color: #0101bc7b;
            }
          }
          .dropdown-item {
            &.active {
              background-color: #f9f9f9 !important;
              @include breakpoint($md-down) {
                background-color: #0101bc7b !important;
              }
              &:hover {
                background-color: #f9f9f9 !important;
              }
            }
          }
        }
        .dropdown {
          &-item {
            padding-left: 10px;
            &:hover,
            &:focus,
            &:active {
              background-color: #0101bc7b;
            }
            &.active {
              background-color: #0101bc7b !important;
            }
          }
        }
      }
    }
  }
  #user-nav-dropdown {
    padding: 0;
  }
}

.guest__logout {
  font-family: $main-font;
  margin: 0;
  margin-right: 7px;
  color: #999999;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.modal-dialog {
  @include breakpoint($md) {
    max-width: 80vw;
  }
  .modal-header {
    font-family: $main-font;
    padding: 0.5rem 1rem;
  }
  h6 {
    color: $red;
    margin-bottom: 0.8rem;
  }
  p {
    margin: 0;
  }
  ul {
    columns: 2;
    list-style: none;
    padding-left: 0;
  }
  .branch-container {
    border-bottom: 1px solid #613f75;
    padding-top: 2rem;
    &:first-of-type {
      background-color: red;
    }
  }
  .row {
    &:nth-child(2) {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
