@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.profile {
  &__jumbotron {
    background: #eeeaf0;
    padding: 2rem 5rem;
    text-align: center;
  }
  &__orders {
    //
    h5 {
      background-color: #fff;
      padding: 1rem 2rem;
      @include breakpoint($md) {
        padding: 1rem 5rem;
      }
    }
    &__container {
      background-color: #f5f5f5;
      padding: 1rem;
      @include breakpoint($md) {
        padding: 1rem 5rem;
        min-height: 500px;
      }
      p {
        margin-bottom: 0;
      }
    }
    &__order {
      .card-body {
        p {
          color: #959595;
          font-size: 0.9rem;
          margin-right: 5px;
        }
        ul {
          padding-left: 1rem;
          margin-bottom: 0;
          li {
            font-family: $main-font;
          }
        }
        & > div {
        }
      }
    }
  }
}
