@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.confirmation {
  &__content {
    background-color: #fff;
    text-align: center;
    margin: 0 auto;
    padding: 3.1rem 0;
    box-shadow: 0 5px 8px 3px rgba(170, 170, 170, 0.08);
    border-radius: 4px;
    @include breakpoint($md-down) {
      position: relative;
      z-index: 2;
    }
    @include breakpoint($md) {
      width: 80%;
    }
    h4 {
      font-weight: bolder;
      margin-top: 1rem;
    }
    h5 {
      margin-top: 0.5rem;
      span {
        font-size: 21px;
        color: #6f6f6f;
      }
    }
    p {
      font-family: $secondary-font;
      font-size: 15px;
      color: #626262;
      letter-spacing: 0;
      text-align: center;
      line-height: 22px;
      margin: 1rem auto 2rem auto;
      width: 92%;
      @include breakpoint($md) {
        width: 80%;
      }
    }
    img {
      &.confirmation-error {
        height: 70px;
      }
    }
    a {
      background: #613f75;
      border-radius: 9px;
      font-family: $main-font;
      text-decoration: none;
      color: #fff;
      width: 263px;
      height: 51px;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
    }
  }
  &__wrapper {
    background: #f5f5f5;
    padding: 3.1rem 0;
    position: relative;
    &::before {
      content: "";
      background-image: url("../../assets/img/purple-rectangle--opacity.svg");
      background-repeat: no-repeat;
      position: absolute;
      top: 1rem;
      width: 145px;
      height: 145px;
      left: 1.5rem;
      z-index: 1;
      @include breakpoint($md) {
        top: 8rem;
        z-index: 1;
      }
    }
    &::after {
      content: "";
      background-image: url("../../assets/img/oval-orange-opacity.svg");
      background-repeat: no-repeat;
      position: absolute;
      bottom: 1rem;
      width: 152px;
      height: 152px;
      right: 1.5rem;
      z-index: 1;
      @include breakpoint($md) {
        bottom: 8rem;
        z-index: 1;
      }
    }
  }
}
