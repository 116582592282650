@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.cart {
  &__wrapper {
    background-color: #f5f5f5;
    padding: 2.2rem 0;
    h4 {
      margin-bottom: 1rem;
    }
  }
  &__order-summary {
    box-shadow: 0 5px 8px 3px rgba(170, 170, 170, 0.08);
    border-radius: 4px;
    background-color: #fff;
    position: sticky;
    position: -webkit-sticky;
    top: 1rem;
    left: 0 !important;
    right: unset;
    @include breakpoint($md) {
      width: 90%;
    }
    &__header {
      background-color: $blue;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #fff;
      padding: 1rem 1.2rem;
    }
    &__body {
      max-height: 400px;
      overflow: auto;
      @include breakpoint($md) {
        max-height: 300px;
        overflow: auto;
      }
    }
    &__product {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      width: 100%;
      &:not(:last-child) {
        border-bottom: 1px dashed #d7d7d7;
      }
      &:last-child {
        border-bottom: 1px solid #d7d7d7;
      }
      &__media {
        max-height: 90px;
        max-width: 70px;
      }
      &__info {
        margin-left: 1rem;
        width: 100%;
        h5 {
          font-weight: bolder;
          font-size: 1.1rem;
          @include breakpoint($md-down) {
            font-size: 1.3rem;
          }
        }
        &__specs {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > div {
            display: flex;
            span {
              display: block;
              font-size: 15px;
              color: #b4b4b4;
              letter-spacing: 0;
            }
            h6 {
              font-size: 16px;
              color: #858585;
              letter-spacing: 0;
              span {
                display: unset;
                color: #000;
              }
            }
          }
          &__price {
            h6 {
              color: #15c972 !important;
              font-size: 1.2rem !important;
              margin-right: 15px;
              // margin-right: 10px;
              @include breakpoint($md-down) {
                font-size: 1.4rem !important;
              }
            }
            button {
              background-color: unset;
              border: 0;
              display: flex;
              align-items: center;
              padding: 0;
              @include breakpoint($md-down) {
                img {
                  height: 27px;
                }
              }
            }
          }
        }
      }
    }
    &__keep {
      text-align: center;
      padding-top: 10px;
      a {
        font-family: $main-font;
        color: #626262;
      }
    }
    &__total {
      padding: 0.5rem 1rem 1.2rem 1rem;
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h6 {
          font-family: $main-font;
          font-size: 16px;
          color: #858585;
          letter-spacing: 0;
        }
        h5 {
          span {
            font-family: $main-font;
            font-size: 17px;
            color: #595959;
            letter-spacing: 0;
          }
        }
        h4 {
          font-family: $main-font;
          font-size: 21px;
          color: #626262;
          letter-spacing: 0;
          margin-bottom: 0;
        }
      }
      p {
        margin-top: 4px;
        margin-bottom: 0;
        text-align: center;
        background-color: #f3cfd3;
        border: 1px solid #f3cfd3;
        color: #842029;
        font-family: $main-font;
        padding: 5px 0;
        transition: all 0.2s ease;
        &:hover {
          cursor: pointer;
          border: 1px solid #842029;
        }
      }
    }
  }
}
