@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.individual-product {
  &__wrapper {
    padding: 2.5rem 0;
  }
  &__media {
    text-align: center;
    position: relative;
    padding-top: 0.5rem;
    &::before {
      content: "";
      background-color: rgba(238, 234, 240, 0.43);
      border-radius: 23px;
      width: 100%;
      height: 110%;
      position: absolute;
      top: -1.2rem;
      left: 0;
      z-index: -1;
    }
    img {
      max-height: 450px;
      @include breakpoint($md-down) {
        max-height: 350px;
      }
    }
  }
  &__loading {
    background-color: rgba(238, 234, 240, 0.43);
    min-height: 60vh;
    padding: 3rem 1rem;
    h4 {
      font-size: 1.2rem;
    }
  }
  &__summary {
    margin-top: 3rem;
    @include breakpoint($md) {
      padding: 0 1rem;
      margin-top: 0;
      padding-right: 0;
      padding-left: 2rem;
      position: sticky;
      position: -webkit-sticky;
      top: 1rem;
      left: 0 !important;
      right: unset;
    }
    &__discount {
      // border: 2px solid $red;
      color: #fff;
      background-color: $blue;
      border-radius: 5px;
      font-family: $main-font;
      font-size: 1rem;
      margin-bottom: -0.5rem;
      margin: 0;
      padding: 5px 7px;
      width: max-content;
      margin-bottom: 0.5rem;
      &__description {
        color: #9c9cb6;
        font-size: 0.9rem;
      }
    }
    &__description {
      p {
        text-align: justify;
        @include breakpoint($md) {
          width: 90%;
        }
      }
      button {
        border: 0;
        font-size: 1.1rem;
        font-family: $main-font;
        color: #fff;
        text-decoration: none;
        background-color: #000;
        border-radius: 6px;
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        @include breakpoint($md) {
          width: 300px;
        }
        &:disabled {
          background-color: rgb(99, 99, 99);
        }
        &.btn--red {
          background: #e0181b;
          margin-left: 1rem;
          @include breakpoint($md-down) {
            font-size: 1.2rem;
            height: 60px;
            margin: 1rem auto 0 auto;
            display: flex;
            width: 100%;
          }
          &:hover {
            background-color: #c91417;
          }
          &:disabled {
            background-color: rgb(99, 99, 99);
          }
        }
        // &:last-child {
        // }
      }
    }
    &__category {
      color: $red !important;
    }
    &__condition {
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      padding: 1.1rem 0;
      margin-bottom: 1.1rem;
      @include breakpoint($md) {
        margin: 0.5rem 3rem 1.1rem 0;
      }
      input {
        display: none;
        &:checked + label {
          /* selected style */
          background-color: $purple;
          border: 1px solid #fff;
          color: #fff;
          transform: translateY(-5px);
          .withNoBoxIcon,
          .withBoxIcon {
            background-color: #fff;
          }
          @include breakpoint($md-down) {
            height: 55px;
            width: 135px;
          }
          // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        }
        &:disabled {
          & + label {
            opacity: 0.3;
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
      label {
        // padding: 0.7rem 1.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 50px;
        width: 130px;
        background: #ffffff;
        border: 1px solid #d5d5d5;
        border-radius: 6px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
        }
        &:first-of-type {
          margin-right: 1rem;
        }
        span {
          &.withBoxIcon {
            background-color: #000;
            -webkit-mask-image: url("../../assets/img/with-box-icon.svg");
            mask-image: url("../../assets/img/with-box-icon.svg");
            display: inline-block;
            width: 30px;
            height: 30px;
            mask-repeat: no-repeat;
          }
          &.withNoBoxIcon {
            background-color: #000;
            -webkit-mask-image: url("../../assets/img/no-box-icon.svg");
            mask-image: url("../../assets/img/no-box-icon.svg");
            display: inline-block;
            width: 30px;
            height: 30px;
            mask-repeat: no-repeat;
          }
          &:last-child {
            font-family: $main-font;
            font-size: 17px;
          }
        }
      }
      p {
        color: #696c8a;
        margin-bottom: 0;
        font-family: $main-font;
        font-size: 0.9rem;
      }
    }
    &__title {
      font-weight: bolder;
    }
    &__price {
      p {
        font-size: 35px;
        color: #15c972;
        margin-top: 0.5rem;
        font-family: $main-font;
      }
      span {
        font-family: $secondary-font;
        font-size: 16px;
        color: #b4b4b4;
        letter-spacing: 0;
      }
      &__withDiscount {
        span {
          text-decoration: none !important;
          color: $red !important;
          font-weight: bolder;
          font-size: 1.1rem !important;
        }
        p {
          color: #626262;
          font-size: 25px;
          text-decoration: line-through;
          margin-top: 0;
          margin-right: 10px;
          margin-bottom: 0;
          font-family: $main-font;
          &:last-child {
            font-family: $main-font;
            font-size: 35px;
            color: #15c972;
            letter-spacing: 0;
            margin-bottom: 0;
            text-decoration: none;
            // margin-top: 5px;
          }
        }
      }
    }
    &__stock {
      // border-top: 1px solid #e7e7e7;
      margin-top: 1rem;
      @include breakpoint($md) {
      }

      .accordion {
        button {
          background-color: $blue;
          color: #fff;
          border-radius: 7px !important;
          @include breakpoint($md) {
            max-width: 90%;
          }
          &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
          }
        }
        table {
          width: 100%;
          border-spacing: 0 5px;
          border-collapse: separate;
          @include breakpoint($md) {
            width: 90%;
          }
          th {
            font-family: $main-font;
            border-bottom: 1px solid #ddddfc;
          }
          .yes-stock {
            border: 2px solid #2fac70;
            width: min-content;
            text-align: center;
            border-radius: 10px;
            color: #2fac70;
            font-weight: bolder;
            @include breakpoint($md) {
              padding: 0.3rem 0.5rem;
              font-size: 0.9rem;
            }
          }
          .no-stock {
            border: 2px solid #c62f2f;
            width: min-content;
            text-align: center;
            border-radius: 10px;
            color: #d54d4d;
            font-weight: bolder;
            @include breakpoint($md) {
              padding: 0.3rem 0.5rem;
              font-size: 0.9rem;
            }
            &--alone {
              transform: translateX(25%);
              @include breakpoint($md) {
                transform: translateX(20%);
              }
              @include breakpoint($md) {
                transform: translateX(22%);
              }
            }
          }
          // tr {
          //   &:not(:first-of-type) {
          //     margin-top: 15px;
          //   }
          // }
        }
        &-body {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    h6 {
      font-size: 14px;
      color: #686868;
      letter-spacing: 0;
    }
  }
  &__reviews {
    background-color: #eeeaf0;
    margin-top: 2rem;
    padding: 2rem 0;
    @include breakpoint($md) {
      margin-top: 4rem;
      padding: 4.7rem 0 0 0;
    }
    &__title {
      display: flex;
      align-items: center;
      div {
        background-color: #9c9cb6;
        height: 3px;
        width: 100%;
        &:first-of-type {
          width: 32px;
        }
      }
      h3 {
        margin: 0 10px;
        font-size: 1.5rem;
        color: #613f75;
        font-weight: bolder;
        text-transform: uppercase;
      }
    }
    &__review {
      background: #1a1d32;
      border-radius: 14px;
      border: 1px solid #414461;
      color: #fff;
      padding: 2.1rem 2rem;
      margin-top: 1rem;
      p {
        opacity: 0.7;
        margin-bottom: 0;
      }
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__info {
          h6 {
            font-size: 21px;
          }
          .star {
            color: #fbb911 !important;
            font-size: 20px;
            margin-right: 0.2rem;
          }
        }
      }
      &__content {
        p {
          font-size: 15px;
          text-align: justify;
          margin-top: 0.6rem;
        }
      }
    }
    &__form {
      background: #262228;
      padding: 5.6rem 0;
      margin-top: 5rem;
      form {
        background: #1a1d32;
        border-radius: 24px;
        margin-top: -7rem;
        padding: 2.5rem 2rem;
        @include breakpoint($md) {
          padding: 2.5rem 3.1rem;
        }
        h4 {
          color: #fff;
          margin-bottom: 1rem;
        }
        label {
          opacity: 0.66;
          font-family: $main-font;
          font-size: 1rem;
          color: #ffffff;
          letter-spacing: 0;
        }
        input,
        textarea {
          background: #1e244a;
          border-radius: 3px;
          border: 0;
          color: #fff;
        }
        input {
          padding: 15px;
          &.is-invalid {
            border: 1px solid #dc3545 !important;
            padding-right: calc(1.5em + 0.75rem);
            background-color: rgb(255, 224, 224);
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
          }
        }
        .invalid-feedback {
          border: 1px solid #dc3545;
          padding: 0.5rem 0.8rem;
          margin-top: 0.8rem;
          border-radius: 3px;
        }
        textarea {
          resize: none;
          &.is-invalid {
            border: 1px solid #dc3545 !important;
            padding-right: calc(1.5em + 0.75rem);
            background-color: rgb(255, 224, 224);
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
          }
        }
        #submitButton {
          background: #ffa552;
          border-radius: 14px;
          border: 0;
          font-family: $main-font;
          color: #fff;
          height: 64px;
          width: 322px;
          font-size: 1.2rem;
          display: block;
          margin: 1rem auto;
          transition: all 0.3s ease;
          &:hover {
            background: #e28c3c;
          }
        }
      }
      &__container {
        background: #1a1d32;
        border-radius: 24px;
        margin-top: -7rem;
        padding: 2.5rem 2rem;
        color: #fff;
        a {
          color: #fff;
        }
      }
    }
  }
  &__purchase-nav {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #f5f5f5;
    padding: 1rem 2rem;
    z-index: 3;
    @include breakpoint($md-down) {
      padding: 1.3rem 2rem;
      display: none;
    }
    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include breakpoint($md-down) {
        display: none;
      }
      @include breakpoint($md) {
        width: 45%;
      }
      @include breakpoint($lg) {
        width: 50%;
      }
      h2 {
        font-size: 1.6rem;
      }
      h4 {
        color: #15c972;
        font-size: 1.8rem;
      }
    }
    &__cta {
      display: flex;
      align-items: center;
      @include breakpoint($md-down) {
        width: 100%;
      }
      button {
        font-size: 1.1rem;
        font-family: $main-font;
        color: #fff;
        text-decoration: none;
        background-color: #000;
        border-radius: 6px;
        border: 0;
        height: 49px;
        min-width: 242px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        position: relative;
        z-index: 99;
        @include breakpoint($md-down) {
          display: none;
        }
        &:hover {
          background-color: rgb(53, 53, 53);
        }
        &:disabled {
          background-color: rgb(99, 99, 99);
          cursor: not-allowed;
        }
        &.btn--red {
          background: #e0181b;
          margin-left: 1rem;
          @include breakpoint($md-down) {
            font-size: 1.2rem;
            height: 52px;
            margin: 0 auto;
            display: flex;
            width: 90%;
          }
          &:hover {
            background-color: #c91417;
          }
          &:disabled {
            background-color: rgb(99, 99, 99);
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

// .footer {
//   border-top: 1px solid #686868;
// }
