@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.overlay {
  &.show {
    width: 100%;
    height: calc(110vh * 3.2);
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 3;
    overflow-y: hidden;
    @include breakpoint($md) {
      height: 100%;
    }
  }
}

.register {
  &__wrapper {
    background: #f5f5f5;
  }
  &__form {
    height: 100%;
    padding: 3.1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint($md) {
      padding: 3.1rem 2rem;
    }
    h5 {
      @include breakpoint($md-down) {
        font-size: 1.5rem;
      }
    }
    span {
      margin-bottom: 1rem;
      font-size: 0.9rem;
      color: #878787;
    }
    input {
      &:not([type="checkbox"]) {
        background: #ffffff;
        border: 1px solid #dad8d8;
        border-radius: 6px;
        height: 48px;
      }
      &.is-invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }
    }
    label {
      font-weight: bolder;
      font-size: 16px;
      color: #000000;
      font-family: $main-font;
      font-size: 1rem;
      @include breakpoint($md) {
        font-size: 0.9rem;
      }
    }
    button {
      background: #613f75;
      border-radius: 9px;
      font-family: $main-font;
      font-size: 1.1rem;
      text-decoration: none;
      color: #fff;
      width: 100%;
      height: 60px;
      display: flex;
      margin: 1rem auto 0 auto;
      align-items: center;
      justify-content: center;
      border: 0;
      transition: all 0.3s ease-in-out;
      z-index: 9;
      position: relative;
      &:hover {
        background-color: darken(#613f75, 5%);
      }
      @include breakpoint($md) {
        font-size: 1.1rem;
        height: 51px;
      }
    }
  }
  &__perks {
    background: #eeeaf0;
    position: relative;
    padding: 2.6rem 1rem;
    @include breakpoint($md) {
      padding: 2.6rem 2rem 2.6rem 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &::before {
        content: "";
        background-color: #eeeaf0;
        position: absolute;
        left: -1rem;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    @include breakpoint($lg) {
      padding: 4.6rem 3rem;
    }

    h3,
    p {
      text-align: center;
      z-index: 2;
      position: relative;
    }
    h3 {
      font-weight: bolder;
      font-size: 2.6rem;
    }
    h4 {
      // font-size: 15px;
      font-size: 1.2rem;
      color: #626262;
      letter-spacing: 0;
      text-align: center;
      line-height: 22px;
      position: relative;
      z-index: 2;
      @include breakpoint($md) {
        margin: 1rem auto;
        width: 85%;
      }
    }
    h5 {
      font-size: 1.5rem;
      color: $blue;
      position: relative;
      z-index: 2;
      text-align: center;
      @include breakpoint($md) {
        margin: 1rem auto;
        width: 85%;
      }
    }
    ul {
      margin-top: 1.5rem;
      list-style-image: url("../../assets/img/check-icon--green.svg");
      z-index: 2;
      position: relative;
      text-align: justify;
      @include breakpoint($md-down) {
        padding-left: 0;
        text-align: center;
      }
      li {
        list-style: none;
        display: inline-block;
        font-weight: bolder;
        &:not(:first-child) {
          margin-top: 1rem;
        }
        &::before {
          content: "";
          display: inline-block;
          height: 20px;
          width: 20px;
          background-image: url("../../assets/img/check-icon--green.svg");
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 0.5em;
        }
      }
    }
  }
}
