@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.legal {
  &__header {
    background-color: #000;
    color: #fff;
    padding: 3rem 1rem;
    @include breakpoint($md) {
      padding: 3rem 4rem;
    }
    p {
      font-size: 0.9rem;
      margin-top: 10px;
      opacity: 0.6;
    }
  }
  &__content {
    padding: 3rem 1rem;
    @include breakpoint($md) {
      padding: 3rem 4rem;
    }
    li {
      &::marker {
        font-size: 1.5rem;
      }
    }
    p {
      text-align: justify;
      margin-bottom: 0;
    }
  }
  border-bottom: 1px solid #cacaca;
}
