// Color fonts
$blue: #3454d1;
$light-grey: #f8f8f6;
$red: #e0181b;
$purple: #613f75;
$brown: #7e4225;
$section-bg: #f8f8f6;
$yellow: #f6b95d;

// Font variables
$main-font: "Rajdhani", sans-serif;
$secondary-font: "Open Sans", sans-serif; ;
