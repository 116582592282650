@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.sell-catalogue {
  position: relative;
  &__jumbotron {
    background-image: linear-gradient(90deg, #ff357f 8%, #5c74ee 96%);
    padding: 1.3rem 0;
    @include breakpoint($md) {
      padding: 2rem;
    }
    h2 {
      color: #fff;
      text-align: center;
      font-size: 1.2rem;
      @include breakpoint($md) {
        font-size: 32px;
      }
    }
    p {
      color: #fff;
      font-size: 0.9rem;
      margin-bottom: 0;
      text-align: center;
      a {
        color: #fff;
      }
    }
  }
  &__container {
    background-color: #f5f5f5;
    padding-bottom: 3rem;
  }
  &__search {
    background-color: #eeeaf0;
    border-bottom: 7px solid transparent;
    border-image: linear-gradient(90deg, #56e39f 8%, #3454d1 53%, #613f75 96%);
    border-image-slice: 1;
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 1.1rem;
    @include breakpoint($md) {
      width: 70%;
      padding: 1.1rem 100px;
      margin: 0 auto;
    }
    h5 {
      font-size: 1rem;
      @include breakpoint($md) {
        font-size: 1.1rem;
      }
    }
    .form-control {
      margin-top: 10px;
      border: 1px solid #dad8d8;
    }
  }
  &__results {
    margin-top: 1rem;
    @include breakpoint($md) {
      margin-top: 2rem;
    }
    &__container {
      padding: 0 1rem;
      @include breakpoint($md) {
        padding: 0 3rem;
      }
      p.game-category {
        font-family: $main-font;
        color: #fff;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        font-size: 1.1rem;
        display: block;
        width: 100%;
        &:not(:first-of-type) {
          margin-top: 1.5rem;
        }
        &.switch {
          background-color: #00b1d2;
        }
        &.ps5 {
          background-color: #1d488f;
        }
        &.xbox-series-x {
          background-color: #037f04;
        }
        &.ps4 {
          background-image: linear-gradient(180deg, #003e93 0%, #157dc2 100%);
        }
        &.xbox-one {
          background-color: #015d02;
        }
        &.ps3 {
          background-color: #007ec3;
        }
        &.gamecube {
          background-color: #4f5496;
        }
        &.n64,
        &.n3ds,
        &.sne,
        &.wii,
        &.nes,
        &.ds,
        &.gba,
        &.gameboy-color,
        &.gameboy,
        & {
          background-color: #e80213;
        }
        &.ps2 {
          background-color: #007ec3;
        }
        &.xbox {
          background-color: #00e000;
        }
        &.wiiu {
          background-color: #00b1d2;
        }
        &.psvita,
        &.psp {
          background-color: #1d488f;
        }
        &.dreamcast {
          background-color: #00f;
        }
      }
      .row > * {
        margin-top: 1rem;
      }
      .product__card {
        background: #ffffff;
        border-radius: 5px;
        border: 0;
        padding-top: 1.5rem;
        transition: all 0.3s ease-in-out;
        position: relative;
        @include breakpoint($md) {
          &:hover {
            transform: translateY(-10px);
            box-shadow: 0 5px 7px 4px rgba(195, 195, 195, 0.32);
          }
        }
        a {
          text-decoration: none;
          height: 100%;
        }
        img {
          max-height: 200px;
          // width: 134px;
          width: auto;
          display: block;
          margin: 0 auto;
          max-width: 140px;
          @include breakpoint($md) {
            height: 200px;
            max-width: 180px;

            // height: 180px;
          }
          &.n64 {
            height: 130px;
          }
        }
        .card-body {
          padding: 0.5rem 0.5rem 1.5rem 0.5rem;
          @include breakpoint($md) {
            padding: 0.5rem 1rem 1.5rem 1rem;
          }
        }
        &__price {
          font-family: $main-font;
          font-size: 24px;
          color: #15c972;
          letter-spacing: 0;
          margin-bottom: 0;
          line-height: 12px;
          background-color: unset;
          text-align: unset;
          padding: unset;
        }
        span {
          font-size: 0.75rem;
          color: #626262;
          padding-left: 2px;
          @include breakpoint($md-down) {
            font-size: 0.75rem;
            display: block;
            padding-right: 0;
            padding-left: 0;
            margin-bottom: 5px;
          }
        }
        h5 {
          color: #000;
          margin-top: 5px;
          @include breakpoint($md-down) {
            font-size: 1rem;
          }
        }
      }
    }
  }
  &__legal {
    background-color: #131624;
    color: #fff;
    padding: 2rem 1rem;
    position: fixed;
    bottom: 0;
    h5 {
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    p {
      font-size: 0.9rem;
      margin-bottom: 0;
    }
  }
}
