@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.search {
  &__category {
    background-image: linear-gradient(90deg, #dc35ff 8%, #5ca2ee 96%);
    border-radius: 4px;
    color: #fff;
    padding: 1rem 0.9rem;
    width: 60%;
    margin-top: 1rem;
    @include breakpoint($md) {
      padding: 1rem 1.1rem;
      font-size: 1.25rem;
      margin-top: 0;
      width: 30%;
    }
    h5 {
      font-size: 1.1rem;
      @include breakpoint($md) {
        font-size: 1.25rem;
      }
    }
  }
  &__wrapper {
    background-color: #f5f5f5;
    padding-bottom: 2.3rem;
    @include breakpoint($md) {
      padding: 2.3rem 0;
      .container-fluid {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
  &__container {
    // margin-top: 1rem;
    @include breakpoint($md) {
      margin-top: 0;
    }
    .product__card {
      min-height: 320px;
    }
  }
}
