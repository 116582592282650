@import "./abstracts/variables";
@import "./abstracts/mixins";
// @import "./components/notifications";
// @import "./components/buttons";
// @import "./components/navbar";
// @import "./components/header";
// @import "./components/jumbotron";
// @import "./components/footer";
// @import "./pages/home";

body,
p {
  font-family: $secondary-font;
  // margin-top: 134px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $main-font;
  margin-bottom: 0;
}

// #card-element {
//   font-family: $main-font;
// }
.StripeElement {
  padding: 0.8rem;
  border: 1px solid #dad8d8;
  border-radius: 6px;
  font-family: $main-font !important;

  input {
    font-family: $main-font !important;
  }
  &--empty {
    font-family: $main-font !important;
    border-color: #dc3545 !important;
    background-color: rgb(255, 240, 240);
  }
}

.InputElement {
  font-family: $main-font !important;
}

#card-element {
  // background: #f2f2f2;
  // border-radius: 3px;
  // border: none;
  // padding: 1rem;
  // margin-top: 1rem;
  font-family: $main-font;
}
