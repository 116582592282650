@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.catalogue {
  &__wrapper {
    background-color: #f5f5f5;
    padding-bottom: 2.3rem;
    @include breakpoint($md) {
      padding: 1rem 0;
      .container-fluid {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
  &__category {
    background-image: linear-gradient(90deg, #3454d1 8%, #9056b2 96%);
    border-radius: 4px;
    padding: 1rem 1.1rem;
    width: 50%;
    // margin-top: 1rem;
    @include breakpoint($md) {
      margin-top: 0;
      width: 30%;
      margin-bottom: 1rem;
    }
    h5 {
      color: #fff;
      text-transform: capitalize;
    }
  }
  &__filters {
    background: #ffffff;
    box-shadow: 0 5px 8px 3px rgba(170, 170, 170, 0.08);
    border-radius: 4px;
    padding: 1.2rem 1rem;
    @include breakpoint($md) {
      width: 95%;
    }
    @include breakpoint($md-down) {
      display: none;
    }
    & > div {
      padding: 0.5rem 0;
      &:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
      }
    }
    h6 {
      font-size: 16px;
      color: #2741a6;
      font-weight: bolder;
      margin-bottom: 0.7rem;
    }
    &__cta {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 1rem 0;
      @include breakpoint($md-down) {
        display: none;
      }
      h6 {
        font-size: 1rem;
        color: #626262;
        letter-spacing: 0.56px;
        margin-left: 0.5rem;
        text-transform: uppercase;
      }
      &--mobile {
        // display: none;
        // @include breakpoint($md-down) {
        background: #ffffff;
        border: 1px solid #d5d5d5;
        border-radius: 6px;
        display: flex;
        width: max-content;
        align-items: center;
        padding: 12px 11px;
        margin: 1.1rem 0 1.1rem auto;
        &:hover {
          cursor: pointer;
        }
        h6 {
          font-size: 1.1rem;
          color: #626262;
          letter-spacing: 0.56px;
          margin-left: 0.5rem;
          text-transform: uppercase;
        }
        // }
      }
    }
  }
  &__container {
    h4 {
      @include breakpoint($md-down) {
        margin-top: 1rem;
      }
    }
    p {
      font-size: 0.9rem;
      color: #919191;
      margin-top: 5px;
      a {
        color: #27a344;
        font-weight: bolder;
      }
    }
    // a {
    //   text-decoration: none;
    //   height: 100%;
    //   &:hover {
    //     & > div {
    //       transform: translateY(-10px);
    //       box-shadow: 0 5px 7px 4px rgba(195, 195, 195, 0.32);
    //     }
    //   }

    .product__card {
      background: #ffffff;
      border-radius: 5px;
      border: 0;
      padding-top: 1.5rem;
      transition: all 0.3s ease-in-out;
      position: relative;
      @include breakpoint($md) {
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 5px 7px 4px rgba(195, 195, 195, 0.32);
        }
      }
      &.withDiscount {
        padding-top: 2rem;
      }
      a {
        text-decoration: none;
        height: 100%;

        // & > div {
        //
      }
      img {
        max-height: 200px;
        // width: 134px;
        width: auto;
        display: block;
        margin: 0 auto;
        max-width: 180px;
        @include breakpoint($md) {
          height: 200px;
          max-width: 180px;

          // height: 180px;
        }
        &.n64 {
          height: 130px;
        }
      }
      &__discount {
        background-color: $blue;
        // background-color: #e9392f;
        // border: 2px solid $red;
        border-radius: 5px;
        display: flex;
        left: 1rem;
        padding: 5px 5px;
        position: absolute;
        text-align: center;
        top: 10px;
        @include breakpoint($md) {
          padding: 5px 7px;
        }
        span {
          color: #fff !important;
          // color: #924a01 !important;
          padding: 0;
          font-size: 0.85rem !important;
          font-family: $main-font;
          letter-spacing: 0.5px;
          @include breakpoint($md) {
            font-size: 0.9rem !important;
          }
        }
      }
      &__price {
        p {
          font-family: $main-font;
          font-size: 24px;
          color: #15c972;
          letter-spacing: 0;
          margin-bottom: 0;
          line-height: 12px;
        }
        &__withDiscount {
          h6 {
            background-color: $blue;
            width: max-content;
            color: #fff;
            padding: 5px;
            border-radius: 5px;
            margin-top: -10px;
            margin-bottom: 5px;
          }
          p {
            color: #626262;
            font-size: 18px;
            text-decoration: line-through;
            margin-top: 0;
            margin-right: 3px;
            &:last-child {
              font-family: $main-font;
              font-size: 24px;
              color: #15c972;
              letter-spacing: 0;
              margin-bottom: 0;
              line-height: 12px;
              text-decoration: none;
              margin-top: 5px;
            }
          }
        }
        span {
          text-decoration: none !important;
          color: $red !important;
          font-weight: bolder;
          font-size: 1rem !important;
        }
      }
      span {
        font-size: 0.75rem;
        color: #626262;
        padding-left: 2px;
        @include breakpoint($md-down) {
          font-size: 0.85rem;
          display: block;
          padding-right: 0;
        }
      }
      h5 {
        color: #000;
        margin-top: 5px;
      }
    }
  }
}
// }

.loading__wrapper {
  min-height: 80vh;
}

.offcanvas-end {
  width: 330px;
  background: #f5f5f5;
  h5 {
    text-transform: uppercase;
    font-size: 1.3rem;
    margin-left: 10px;
  }
  .offcanvas-body {
    & > div {
      background: #ffffff;
      border-radius: 7px;
      padding: 1rem;
      &:not(:first-child) {
        margin-top: 1rem;
      }
      h6 {
        font-size: 21px;
        color: #2741a6;
        letter-spacing: 0;
        margin-bottom: 10px;
      }
      input {
        @include breakpoint($md-down) {
          height: 20px;
          width: 20px;
          margin-right: 8px;
        }
      }
    }
  }
}
