@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.hero-container {
  display: grid;
  grid-template-areas:
    "main"
    "right"
    "footer";
  gap: 10px;
  // padding: 10px;
  margin-bottom: 10px;
  @include breakpoint($md) {
    display: grid;
    grid-template-areas:
      "main  right "
      "footer  footer ";
  }
  .item1,
  .item2,
  .item3 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
  }
  .item1 {
    grid-area: main;
    background-image: url("../../assets/img/hero1.jpeg");
    height: 260px;
    @include breakpoint($xs) {
      height: 240px;
    }
    @include breakpoint($sm2) {
      height: 400px;
    }
    @include breakpoint($md-1) {
      height: 470px;
    }
    @include breakpoint($md) {
      height: 300px;
    }
    @include breakpoint($md-2) {
      height: 450px;
    }
    @include breakpoint($md-3) {
      height: 360px;
    }
    @include breakpoint($lg) {
      height: 400px;
    }
    @include breakpoint($lg2) {
      height: 460px;
    }
    @include breakpoint($xl) {
      height: 510px;
    }
  }
  .item2 {
    grid-area: right;
    background-image: url("../../assets/img/hero2.jpeg");
    height: 280px;
    @include breakpoint($xs) {
      height: 240px;
    }
    @include breakpoint($sm2) {
      height: 340px;
    }
    @include breakpoint($md-1) {
      height: 470px;
    }
    @include breakpoint($md) {
      height: 300px;
    }
    @include breakpoint($md-2) {
      height: 450px;
    }
    @include breakpoint($md-3) {
      height: 360px;
    }
    @include breakpoint($lg) {
      height: 400px;
    }
    @include breakpoint($lg2) {
      height: 460px;
    }
    @include breakpoint($xl) {
      height: 510px;
    }
  }
  .item3 {
    grid-area: footer;
    background-image: url("../../assets/img/hero3--mobile.jpeg");
    height: 240px;
    background-size: cover;
    @include breakpoint($md-1) {
      height: 500px;
    }
    @include breakpoint($md) {
      background-size: cover;
      background-image: url("../../assets/img/hero3.jpeg");
      height: 200px;
    }
    @include breakpoint($lg) {
      height: 250px;
    }
  }
}

.carousel {
  // @include breakpoint($md-down) {
  //   height: 300px;
  // }
  // &-inner,
  // &-item {
  //   height: 100%;
  // }
  &-indicators {
    button {
      background-color: $red !important;
      height: 17px !important;
      width: 17px !important;
      border-radius: 50% !important;
    }
  }
}

.main-products {
  background-color: rgba(97, 63, 117, 0.11);
  padding-top: 2.1rem;
  padding-bottom: 2.1rem;
  position: relative;
  @include breakpoint($md) {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
  &::before {
    content: "";
    background-image: url("../../assets/img/purple-rectangle--opacity.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 8rem;
    width: 145px;
    height: 145px;
    left: 1.5rem;
    z-index: -1;
  }
  h3 {
    margin: 0 auto;
    text-align: center;
    font-weight: bolder;
    width: 80%;
    @include breakpoint($md) {
      width: 50%;
    }
  }
  &__cards {
    margin-top: 1rem;
    @include breakpoint($md) {
      margin-top: 2rem;
      padding: 0 2rem;
    }
    &--xbox-s-x,
    &--nsw,
    &--ps5,
    &--xone,
    &--nsw,
    &--ps4 {
      // box-shadow: 0 2px 10px 6px rgba(0, 0, 0, 0.04);
      // background-color: #eeeaf0;
      position: relative;
      .card {
        color: #fff;
        border-radius: 0;
        overflow: hidden;
        // height: 430px;
        padding: 1.1rem;
        @media (max-width: 769px) and (max-height: 1025px) {
          height: 100%;
        }
        @include breakpoint($md) {
          padding: 2rem;
          height: 560px;
        }
        &-title {
          font-size: 1.8rem;
          text-transform: capitalize;
          font-family: $main-font;
          margin-bottom: 15px;
          @include breakpoint($md) {
            font-weight: bolder;
            font-size: 2.1rem;
            margin-bottom: 0;
          }
        }
        &-body {
          padding: 0;
        }
        p {
          font-size: 15px;
          color: #ffffff;
          @include breakpoint($md-down) {
            display: none;
          }
        }
        a {
          background: #ffffff;
          border-radius: 24.5px;
          display: block;
          font-family: $main-font;
          font-size: 1.1rem;
          text-align: center;
          color: #000;
          text-decoration: none;
          padding: 0.8rem;
          font-weight: bolder;
          transition: all 0.3s ease-in-out;
          box-shadow: 0 3px 7px 2px rgba(0, 0, 0, 0);
          &:last-of-type {
            margin-top: 1rem;
          }
          &:hover {
            transform: translateY(-3px);
            box-shadow: 0 3px 7px 2px rgba(0, 0, 0, 0.23);
          }
        }
        img {
          display: block;
          margin: 1rem auto 0 auto;
          @include breakpoint($md) {
            position: absolute;
            bottom: 0;
          }
          // margin-top: 1rem;
          // margin-left: auto;
          // display: block;
        }
      }
    }
    &--xone,
    &--ps4 {
      .card {
        a {
          @include breakpoint($sm-only) {
            width: 100%;
          }
          // @media (max-width: 1025px) and (max-height: 769px) {
          //   width: 48%;
          // }
          @media (min-width: 1028px) {
            width: 50%;
          }
          @include breakpoint($lg) {
            width: 50%;
          }
        }
        img {
          height: 220px;
          @include breakpoint($md) {
            right: 1rem;
            bottom: 2rem !important;
          }
          @include breakpoint($lg) {
            height: 253px;
          }
        }
      }
    }
    &--xbox-s-x {
      .card {
        background: #037f04;
        img {
          height: 220px;
          @include breakpoint($md) {
            height: 272px;
          }
          @include breakpoint($lg) {
            right: 50%;
            transform: translateX(50%);
            // right: 0;
          }
        }
      }
    }
    &--xone {
      .card {
        background-image: linear-gradient(180deg, #015d02 0%, #037f04 66%);
        box-shadow: 0 2px 10px 6px rgba(0, 0, 0, 0.04);
        a {
          display: inline-block;
          @include breakpoint($md) {
            width: 48%;
            &:last-of-type {
              margin-top: 0;
            }
          }
        }
        img {
          height: 180px;
          @include breakpoint($md) {
            bottom: 0 !important;
            height: 245px;
            right: 50%;
            transform: translateX(50%);
          }
        }
      }
    }
    &--nsw {
      .card {
        background: #00b1d2;
        img {
          height: 147px;
          @media (max-width: 1025px) and (max-height: 769px) {
            height: 120px;
            bottom: 3rem;
          }
          @media (min-width: 1028px) {
            height: 140px;
            bottom: 3rem;
          }
          @include breakpoint($lg) {
            left: 50%;
            transform: translateX(-50%);
            bottom: 2rem;
            height: 170px;
          }
        }
        // a {
        //   &:hover {
        //     transform: translateY(-3px);
        //     box-shadow: 0 3px 7px 2px rgba(0, 0, 0, 0.134);
        //     background-color: lighten(#00b1d2, 40%);
        //     color: #fff;
        //   }
        // }
      }
    }
    &--ps4 {
      .card {
        background-image: linear-gradient(180deg, #003e93 0%, #157dc2 100%);
        box-shadow: 0 2px 10px 6px rgba(0, 0, 0, 0.04);
        img {
          @media (max-width: 1025px) and (max-height: 769px) {
            height: 190px !important;
          }
        }
        a {
          // @include breakpoint($md) {
          //   width: 48%;
          // }
        }
      }
    }
    &--ps5 {
      .card {
        background: #1d488f;
        img {
          height: 272px;
          margin-bottom: -1.2rem;
          @include breakpoint($md) {
            right: 0;
          }
        }
      }
    }
    &:last-child {
      .main-products__cards--xone,
      .main-products__cards--ps4 {
        .card {
          @include breakpoint($md) {
            height: 440px;
          }
        }
      }
    }
  }
}

.other-products {
  padding: 3.1rem 1rem;
  @include breakpoint($xs) {
    padding: 3.1rem 0;
  }
  @include breakpoint($md) {
    padding: 3.1rem 2rem;
  }
  @include breakpoint($md-2) {
    padding: 3.1rem 4rem;
  }
  @include breakpoint($lg) {
    padding: 3.1rem 2rem;
  }
  @include breakpoint($lg2) {
    padding: 3.1rem 2rem;
  }
  h4 {
    background-color: $red;
    color: #fff;
    font-size: 1.3rem;
    padding: 0.7rem;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 0px;
      height: 0px;
      border-top: 10px solid $red;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid transparent;
      bottom: -20px;
      left: 10px;
    }
  }
  &__cards,
  &.accessory {
    align-items: center;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    height: 179px;
    justify-content: flex-end;
    margin-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    width: 48%;
    &:nth-child(odd) {
      margin-right: 0.8rem;
    }
    @include breakpoint($xs) {
      width: 47%;
    }
    @include breakpoint($md) {
      margin-right: 0.7rem;
      margin-left: 1rem;
      height: 205px;
      width: 205px;
    }
    @include breakpoint($md-2) {
      height: 225px;
      width: 225px;
    }
    @include breakpoint($lg) {
      height: 220px;
      width: 217px;
    }
    @include breakpoint($lg2) {
      height: 238px;
      width: 238px;
    }
    @include breakpoint($xl) {
      height: 238px;
      width: 230px;
    }
    img {
      display: block;
      max-height: 99px;
      max-width: 140px;
      transition: all 0.2s ease-in;
      @include breakpoint($md) {
        max-height: 125px;
        max-width: 185px;
      }
      @include breakpoint($lg) {
        max-height: 140px;
        max-width: 185px;
      }
      @include breakpoint($lg2) {
        max-height: 155px;
        max-width: 185px;
      }
    }
    &.accessory {
      a {
        color: #000;
        text-decoration: none;
      }
      img {
        display: block;
        margin: 0 auto;
      }
      h5 {
        text-align: center;
      }
      &:hover {
        text-decoration: underline;
        img {
          transform: translateY(-10px);
        }
      }
    }
    h5 {
      margin-top: 1rem;
    }
    &:hover {
      cursor: pointer;
      .other-products__cards__overlay {
        opacity: 1;
      }
      // img {
      //   transform: translateY(-10px);
      // }
    }
    &__overlay {
      background-color: #6663b2ec;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      transition: all 0.2s ease-in;
      &__cta {
        width: 50%;
        text-decoration: none;
        text-align: center;
        &:last-child {
          position: relative;
          &::before {
            content: "";
            background-color: #fff;
            height: 180px;
            width: 1.5px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }
        &:hover {
          img {
            transform: translateY(-5px);
          }
        }
        h6 {
          color: #fff;
          margin-top: 0.8rem;
        }
        img {
          margin: 0 auto;
          transition: all 0.2s ease-in;
        }
      }
    }
    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 1rem;
      @include breakpoint($sm-only) {
        justify-content: space-between;
      }
    }
  }
  &__brands {
    padding: 0;
    margin-top: 1rem;
    @include breakpoint($md) {
      margin-top: 2rem;
      padding: 0 3.5rem;
    }
    &__brand {
      border: 1px solid #eaeaec;
      padding: 2.6rem 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint($md) {
        padding: 2.6rem 1rem;
      }
      img {
        height: 40px;
        @include breakpoint($md) {
          height: 52px;
        }
      }
      &--nintendo {
        background-color: #e70214;
      }
      &--ps {
        height: 100%;
        img {
          height: 42px;
        }
      }
    }
  }
}

.about-us {
  background-color: #1a1a1e;
  color: #fff;
  padding: 4.5rem 0;
  @include breakpoint($md) {
    padding: 7.5rem 0;
  }
  h3 {
    margin: 2rem 0;
    @include breakpoint($md) {
      margin-top: 0;
      margin-bottom: 2rem;
      width: 80%;
    }
  }
  p {
    text-align: justify;
  }
  a {
    border: 3px solid #56e39f;
    color: #fff;
    display: block;
    font-family: $main-font;
    font-size: 1.1rem;
    padding: 0.5rem 2rem;
    text-decoration: none;
    width: max-content;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #56e39f;
      color: #1a1a1e;
    }
  }
  &__media {
    position: relative;
    &::before {
      content: "";
      background-image: url("../../assets/img/rectangle-figure--purple--mobile.svg");
      background-repeat: no-repeat;
      position: absolute;
      top: -3rem;
      width: 145px;
      height: 145px;
      left: 0;
      z-index: 1;
      @include breakpoint($md) {
        background-image: url("../../assets/img/rectangle-figure--purple.svg");
        top: -4rem;
        left: -2rem;
      }
    }
    &::after {
      content: "";
      background-image: url("../../assets/img/triangle-figure--yellow--mobile.svg");
      background-repeat: no-repeat;
      position: absolute;
      bottom: -38rem;
      width: 145px;
      height: 145px;
      left: 12rem;
      z-index: 2;
      @media (min-width: 1024px) {
        background-image: url("../../assets/img/triangle-figure--yellow.svg");
        bottom: -13rem !important;
      }
      @media (min-width: 768px) and (max-height: 1181px) {
        bottom: -30rem;
      }
      @include breakpoint($md-2) {
        bottom: -13rem;
      }
      @include breakpoint($lg) {
        bottom: -13rem;
      }
    }
    img {
      z-index: 2;
      position: relative;
    }
  }
}

.qa {
  background-color: #000;
  color: #fff;
  padding: 4rem 0 7rem 0;
  position: relative;
  &::before {
    content: "";
    background-image: url("../../assets/img/oval-icon-opacity.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 40%;
    width: 145px;
    height: 188px;
    left: 0;
    z-index: 1;
    // @include breakpoint($md) {
    //   background-image: url("../../assets/img/rectangle-figure--purple.svg");
    //   top: -4rem;
    //   left: -2rem;
    // }
  }
  @include breakpoint($md) {
    padding: 5.5rem 0 8.5rem 0;
  }
  h3 {
    margin: 2rem 0;
    text-align: center;
    @include breakpoint($md) {
      margin-top: 0;
      margin-bottom: 2rem;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .accordion {
    @include breakpoint($md) {
      margin-top: 3.5rem;
      padding: 0 2rem;
    }
    &-item {
      background: #1a1d32;
    }
    &-button {
      background-color: #131524;
      color: #fff;
      font-size: 1.1rem;
      &:after {
        background-image: url("../../assets/img/arrow-down-icon.svg");
        transform-origin: 10px 6px;
      }
      &:not(.collapsed) {
        background-color: #131524;
        &:after {
          background-image: url("../../assets/img/arrow-down-icon.svg");
        }
      }
    }
  }
}
.newsletter {
  padding: 0 1rem;
  @include breakpoint($md) {
    padding: 0 4rem;
  }
  @include breakpoint($lg) {
    padding: 0 7rem;
  }
  .container {
    background-color: #262228;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    color: #fff;
    margin-top: -4.5rem;
    padding: 2rem 1rem;
    position: relative;
    @include breakpoint($md) {
      border-radius: 33px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 3rem;
    }
    h2 {
      @include breakpoint($md-down) {
        font-size: 1.3rem;
        margin-bottom: 1rem;
      }
    }
    input {
      background: #535054;
      border: 0;
      height: 57px;
      color: #fff;
      @include breakpoint($md) {
        width: 314px;
        padding: 1rem;
      }
      @include breakpoint($md-down) {
        padding: 0.8rem 1rem;
        height: 50px;
      }
    }
    button {
      background-color: $red;
      border: 0;
      font-family: $main-font;
      color: #fff;
      font-size: 1.1rem;
      width: 176px;
      height: 57px;
      @include breakpoint($md-down) {
        height: 50px;
      }
      &:hover {
        background-color: darken($red, 5%);
      }
    }
  }
}
