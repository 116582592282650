@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.guest__wrapper {
  @include breakpoint($md) {
    height: 70vh;
    .row {
      height: 100%;
    }
  }
}

.auth-type {
  height: 100%;
  @include breakpoint($md-down) {
    padding: 4rem 0;
  }
  & > div {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    a {
      background-color: $purple;
      display: block;
      width: max-content;
      margin: 1rem auto 0 auto;
      font-family: $main-font;
      color: #fff;
      text-decoration: none;
      border-radius: 12px;
      padding: 0.5rem 6rem;
    }
    p {
      a {
        background-color: unset;
        color: #000;
        padding: 0;
        display: inline-block;
        margin: 0;
        margin-top: 0.5rem;
      }
    }
    button {
      border: 0;
      border-radius: 12px;
      background-color: $blue;
      display: block;
      width: max-content;
      margin: 1rem auto;
      font-family: $main-font;
      color: #fff;
      padding: 0.5rem 1rem;
    }
    &:last-child {
      border-top: 1px solid #dbdbdb;
    }
  }
}
