@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.overlay {
  &.show {
    width: 100%;
    height: calc(110vh * 3.2);
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 2;
    overflow-y: hidden;
    @include breakpoint($md) {
      height: 100%;
    }
  }
}

.login {
  &__content {
    background-color: #fff;
    margin: 0 auto;
    padding: 3.1rem 2rem;
    box-shadow: 0 5px 8px 3px rgba(170, 170, 170, 0.08);
    border-radius: 4px;
    position: relative;
    border-top: 5px solid #56e39f;
    &::after {
      content: "";
      background-image: url("../../assets/img/oval-orange.svg");
      background-repeat: no-repeat;
      position: absolute;
      bottom: -2rem;
      width: 69px;
      height: 69px;
      left: -2rem;
      z-index: 1;
      @include breakpoint($md) {
        left: -2rem;
        bottom: -2rem;
        z-index: 1;
      }
    }
    @include breakpoint($md-down) {
      padding: 0.5rem 1rem 2rem 1rem;
      z-index: 2;
    }
    @include breakpoint($md) {
      width: 55%;
    }
    @include breakpoint($lg) {
      width: 45%;
    }
    h4,
    p {
      text-align: center;
    }
    h4 {
      font-weight: bolder;
      margin-top: 1rem;
    }
    p {
      font-family: $secondary-font;
      font-size: 15px;
      color: #626262;
      letter-spacing: 0;
      text-align: center;
      line-height: 22px;
      margin: 1rem auto 0 auto;
      width: 92%;
      @include breakpoint($md) {
        margin: 1rem auto 2rem auto;
        width: 90%;
      }
    }
    form {
      label {
        font-weight: bolder;
        font-size: 16px;
        color: #000000;
        font-family: $main-font;
      }
      input {
        background: #f5f5f5;
        border: 1px solid #dad8d8;
        border-radius: 6px;
        height: 48px;
      }
      button {
        background: #613f75;
        border-radius: 9px;
        font-family: $main-font;
        text-decoration: none;
        color: #fff;
        width: 100%;
        height: 51px;
        display: flex;
        margin: 1rem auto 0 auto;
        align-items: center;
        justify-content: center;
        border: 0;
        z-index: 9;
        position: relative;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: darken(#613f75, 5%);
        }
      }
      p {
        margin-bottom: 0;
      }
      a,
      p {
        font-size: 13px;
        color: #464646;
        letter-spacing: 0;
        text-align: center;
        line-height: 22px;
        text-decoration: none;
      }
      p {
        a {
          font-weight: bolder;
          font-size: 13px;
          color: #3454d1;
          letter-spacing: 0;
          text-align: center;
          line-height: 22px;
        }
      }
    }
  }
  &__wrapper {
    background: #eeeaf0;
    padding: 3.1rem 0;
    position: relative;
    &::before {
      content: "";
      background-image: url("../../assets/img/purple-rectangle--opacity.svg");
      background-repeat: no-repeat;
      position: absolute;
      top: 1rem;
      width: 145px;
      height: 145px;
      left: -1rem;
      z-index: 1;
      @include breakpoint($md) {
        top: 8rem;
        left: 3rem;
        z-index: 1;
      }
    }
  }
}
