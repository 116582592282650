@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.star-rating-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 25px;
  width: 15px;
  @include breakpoint($md) {
    width: 35px !important;
  }
}
.star-rating {
  display: flex;
  @include breakpoint($md-down) {
    margin-left: -1rem;
  }
}
.on {
  color: #fbb911;
}
.off {
  color: #ccc;
}
