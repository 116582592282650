@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.cookie-consent-banner {
  background-color: $blue !important;
  @include breakpoint($md) {
    width: 60% !important;
    right: unset !important;
    left: 0 !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    z-index: 9999999 !important;
  }
  &__header {
    h5 {
      text-transform: uppercase;
      margin-bottom: 7px;
    }
  }
  &__description {
    p {
      margin-bottom: 0;
      font-size: 0.8rem;
    }
  }
  & > div {
    margin: 15px;
    width: 100%;
    &:nth-child(2n) {
      margin-top: 0 !important;
    }
  }
  button {
    font-family: $main-font !important;
    // margin: 0 15px 15px 15px !important;
    width: 100%;
    display: block;
    margin: 0 !important;
    // margin: 0 15px !important;
    @include breakpoint($md) {
      min-width: 120px;
      font-size: 1.1rem;
    }
  }
  a {
    color: #fff;
    font-family: $main-font;
    font-weight: bolder;
    font-size: 1.05rem;
    margin-left: 5px;
  }
}
