@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.email-confirm {
  &__wrapper {
    background: #eeeaf0;
    padding: 3.1rem 0;
  }
  &__content {
    background-color: #fff;
    padding: 3rem 1rem;
    text-align: center;
    box-shadow: 0 5px 8px 3px rgba(170, 170, 170, 0.18);
    max-width: 90%;
    margin: 0 auto;
    @include breakpoint($md) {
      padding: 4rem 1rem;
      max-width: 50%;
      margin: 0 auto;
    }
    .spinner-border {
      margin-top: 1rem;
    }
    &__feedback {
      img {
        max-height: 50px;
        margin-top: 0.5rem;
      }
      p.error {
        color: #842029;
      }
    }
  }
}
