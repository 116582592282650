@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.payment-form {
  margin-top: 2rem;
  @include breakpoint($md) {
    margin-top: 0;
    margin-left: -2rem;
  }
  &__header {
    background: #1a1d32;
    padding: 1.1rem 0.8rem;
    @include breakpoint($md) {
      padding: 1rem 1.5rem;
    }
    h4 {
      color: #fff;
      margin-bottom: 0;
      font-size: 1.2rem;
      display: flex;
      span {
        background: #df191b;
        height: 24px;
        width: 24px;
        display: inline-flex;
        text-align: center;
        border-radius: 50%;
        font-size: 1rem;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
      }
    }
  }
  &__card-section {
    background-color: #fff;
    padding: 1rem 0.8rem;
    @include breakpoint($md) {
      padding: 1rem 1.5rem;
    }
    img {
      height: 28px;
      &.cash-icon {
        height: 33px;
      }
    }
    .form-payment-label {
      font-size: 1.1rem;
    }
    .mercado-pago {
      button {
        margin-top: 0;
        background-color: #03a8d1;
        &:hover {
          background-color: #3b4589;
        }
      }
    }
  }
  &__shipment-type__container,
  &__contact-info__container {
    background-color: #fff;
    box-shadow: 0 5px 8px 3px rgba(170, 170, 170, 0.08);
    border-radius: 4px;
    padding: 1.5rem 0.8rem;
    @include breakpoint($md) {
      padding: 1.5rem 2rem;
    }
  }
  p {
    font-size: 14px;
    color: #424242;
    letter-spacing: 0;
  }
  input[type="radio"] {
    &.input-payment-form-option {
      display: none;

      &:checked + label {
        /* selected style */
        background-color: #7a4f94;
        border: 1px solid #fff;
        color: #fff;
        transform: translateY(-5px);
        .cash-icon,
        .card-icon {
          background-color: #fff;
        }
        @include breakpoint($md-down) {
          height: 65px;
        }
        // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      }
      &:disabled {
        & + label {
          opacity: 0.3;
          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .payment-option-label {
    // padding: 0.7rem 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 50px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.3s ease-in-out;
    font-size: 1.1rem;
    &:hover {
      cursor: pointer;
    }
    span {
      &.card-icon {
        background-color: #000;
        -webkit-mask-image: url("../../assets/img/credit-card--black.svg");
        mask-image: url("../../assets/img/credit-card--black.svg");
        display: inline-block;
        width: 32px;
        height: 25px;
        mask-repeat: no-repeat;
        margin-right: 8px;
      }
      &.cash-icon {
        background-color: #000;
        -webkit-mask-image: url("../../assets/img/cash-icon--black.svg");
        mask-image: url("../../assets/img/cash-icon--black.svg");
        display: inline-block;
        width: 40px;
        height: 30px;
        mask-repeat: no-repeat;
        margin-right: 5px;
      }
    }
  }
  input {
    &:not([type="radio"]) {
      background: #f5f5f5;
      border: 1px solid #dad8d8;
      border-radius: 6px;
      min-height: 48px;
    }
    &.is-invalid {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
  button {
    margin: 1.5rem 0;
    background-color: $purple;
    border: 0;
    font-family: $main-font;
    color: #fff;
    padding: 1rem;
    width: 100%;
    font-size: 1.1rem;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 999999;
    &:hover {
      background-color: darken($purple, 5%);
    }
    &:disabled {
      cursor: progress;
    }
  }
  label {
    font-size: 15px;
    font-family: $main-font;
    font-weight: bolder;
  }
  .form-check {
    label {
      font-weight: lighter;
      font-size: 1.1rem;
    }
  }
  #card-errors {
    p {
      margin-bottom: 0;
      margin-top: 10px;
      font-family: $main-font;
      font-size: 1.1rem;
      text-align: center;
      color: #dc3545;
    }
  }
}
