@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.footer {
  &-newsletter {
    margin-top: 3.5rem;
  }
  &-logo {
    height: 84px;
  }
  h5 {
    margin-bottom: 10px;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  &-social {
    display: flex;
    flex-direction: column;
    a {
      color: #000;
      font-family: $main-font;
      text-decoration: none;
      font-size: 1.1rem;
    }
  }
  &__bottom {
    background-color: #000;
    padding: 5px 2rem;
    p {
      color: #fff;
      margin: 0;
      font-size: 0.9rem;
    }
  }
}
